import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { Mask } from 'components/mask';
import { TcIconShut } from 'components/IconSystem';

import './style.less';

export const Modal = function (props) {
  let safeDoc = window.document;

  // 当前页面被 iframe 嵌套时，检查父子页面是否同源且允许操作
  if (window.top !== window) {
    try {
      safeDoc = window.top.document;
    } catch (e) {}
  }

  return createPortal(
    <Fragment>

      {props.onClose && <Mask onClick={event => props.onClose(event)} />}

      <div className="modal">

        <div className="modal-content">
          {props.onClose && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              className="modal-close"
              onClick={event => props.onClose(event)}
              role="button"
              tabIndex="0"
            >
              <TcIconShut />
            </div>
          )}

          {props.children}
        </div>
      </div>
    </Fragment>
    , safeDoc.body,
  );
};

Modal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.element,
};
